import React from "react"
import { graphql } from "gatsby"
import useScript from "../hooks/useScript"

import Layout from "../components/Layout/Layout"
import SEO from "../components/seo"
import ModernFullRow from "../components/ModernFullRow/ModernFullRow"
import ModernAccountRow from "../components/ModernAccountRow/ModernAccountRow"
import ModernLoginRow from "../components/ModernLoginRow/ModernLoginRow"
import NewsletterBox from "../components/NewsletterBox/NewsletterBox"

// Styles
import "../styles/vars.css"
import "../styles/main.css"
import "../styles/buttons.css"

import styles from "./index.module.css"

const Index = ({ data, location }) => {
  // eslint-disable-next-line no-unused-vars
  const [paddleLoaded, paddleError] = useScript(
    "https://cdn.paddle.com/paddle/paddle.js"
  )
  if (paddleLoaded) {
    window.Paddle.Setup({ vendor: 111779 })
  }

  const siteTitle = data.site.siteMetadata.title
  const siteDesc = data.site.siteMetadata.description

  return (
    <Layout location={location} title={siteTitle} size="lg">
      <SEO
        title={`Keycloak Themes`}
        description={siteDesc}
        titleTemplate={false}
      />
      <div className={styles.topBanner}><a href="/blog/closing-keycloakthemes">Closing KeycloakThemes. Read the announcement</a>.</div>
      <main className={styles.main}>
        <h2>Our themes</h2>
        <span className={styles.strong}>KeycloakThemes</span> sells site
        templates for the open source{" "}
        <span className={styles.strong}>
          <a href="https://www.keycloak.org/" target="__blank">
            Keycloak Identity Management
          </a>
        </span>{" "}
        solution. We do the hard work to create and customize Keycloak
        templates, this way you can install them in minutes.
        <ModernFullRow paddleLoaded={paddleLoaded} />
        <ModernAccountRow paddleLoaded={paddleLoaded} />
        <ModernLoginRow paddleLoaded={paddleLoaded} />
      </main>

    </Layout>
  )
}

export default Index

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`
// allNotionPageBlog(
//   filter: { isDraft: { eq: false } }
//   sort: { fields: [indexPage], order: DESC }
// ) {
//   edges {
//     node {
//       title
//       slug
//       excerpt
//       tags
//       pageIcon
//       createdAt
//     }
//   }
// }
