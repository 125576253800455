import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { StaticQuery, graphql } from "gatsby"

import styles from "./modernFullRow.module.css"

const ModernFullRow = ({ paddleLoaded }) => {
  return (
    <StaticQuery
      query={query}
      render={data => (
        <div className={styles.product}>
          <h3>Modern Bundle: Account + Login Themes</h3>
          <p>
            A bundle with the Account Console Theme and the Login Theme for
            Keycloak. It changes the styles of all login-related pages and all
            user account pages.
          </p>
          <div className={styles.card}>
            <Link to={`/themes/modern-bundle`}>
              <img
                src={data.logo.childImageSharp.fluid.originalImg}
                alt={"Modern is a responsive and clean Keycloak theme."}
                className={styles.banner}
              />
            </Link>
            <div className={styles.bannerFooter}>
              <div className={styles.priceBlock}>
                <span className={styles.price}>€89.00 + VAT</span>
              </div>
              <span className={styles.buttons}>
                <Link to={`/themes/modern-bundle`} className="btn btn-default">
                  View more
                </Link>
                {false && paddleLoaded && (
                  <a
                    href="#!"
                    className="btn"
                    onClick={() => {
                      window.Paddle.Checkout.open({ product: 746827 })
                    }}
                  >
                    Buy Now!
                  </a>
                )}
              </span>
            </div>
          </div>
        </div>
      )}
    />
  )
}

ModernFullRow.propTypes = {
  paddleLoaded: PropTypes.bool.isRequired,
}

const query = graphql`
  query {
    logo: file(absolutePath: { regex: "/modern_bundle_banner.jpg/" }) {
      childImageSharp {
        fluid {
          originalImg
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`

export default ModernFullRow
